body {
  padding-bottom: 30px;
}

strong,
b {
  font-weight: 700;
}

u {
  text-decoration: none;
}

.dixa-support {
  width: 100%;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Playfair Display', serif;
}

.gladlyHC {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
}

.gladlyHC-faqContainer {
  max-width: 90vw;
  margin: 0px auto 36px;
}

.gladlyHC-answersIndex-link,
.gladlyHC-answersIndex-container,
.gladlyHC-answerDetail,
.gladlyHC-answerSection,
.gladlyHC-searchResultsContainer {
  max-width: 90vw;
  margin: 0px auto 36px;
}

.gladlyHC-answersIndex-list {
  line-height: 24px;
}

.gladlyHC-searchContainer {
  background-image: url('../img/faq-bg.jpeg');
  padding: 100px 10px 100px;
  background-size: cover;
}

.gladlyHC-searchInput {
  border: solid 1px #d0d0d0;
  height: 48px;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400;
  padding: 0 0 0 18px;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 6px;
}

.gladlyHC-searchMenuContainer {
  max-width: 824px;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 0 12px;
}

.gladlyHC-faqHeading {
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
  text-align: center;
  padding-top: 36px;
  text-transform: capitalize;
}

.gladlyHC-faqSections {
  margin: 30px 0;
}

.gladlyHC-faqSection {
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  margin: 6px;
  padding: 24px 18px 18px;
  flex-basis: 50% !important;
}

.gladlyHC-faqSection-header {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding-bottom: 12px;
}

.gladlyHC-faqSection-list {
  margin: 10px 0 0 0;
}

.gladlyHC-faqSection-listItem {
  margin: 0 0 12px 0;
}

.gladlyHC-faqSection-listItem a {
  color: #000;
  text-decoration: none;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.gladlyHC-faqSection-listItem a:hover {
  color: #fb6a66;
  text-decoration: underline;
}

.gladlyHC-answersIndex-link a {
  color: #000;
  text-decoration: none;
}

.gladlyHC-answersIndex-link a:hover {
  color: #fb6a66;
  text-decoration: underline;
}

.gladlyHC-answerDetail-backLink {
  color: #000;
  text-decoration: none;
  padding: 18px 0 12px 0;

}

.gladlyHC-answerDetail-backLink a:hover {
  color: #fb6a66;
  text-decoration: underline;
}

.gladlyHC-answerDetail-name {
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  margin: 0 0 24px;
  text-transform: capitalize;
}

.gladlyHC-answerDetail-content ul {
  list-style-type: disc;
  padding: 20px;
}


.gladlyHC-searchResultsSection-heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin: 0 0 18px;
}

.gladlyHC-searchResultsSection-result {
  margin: 10px 0;
}

.gladlyHC-searchResultsSection-result a {
  color: #000;
  text-decoration: none;
  font-weight: 400;
}

.gladlyHC-searchResultsSection-result a:hover {
  color: #fb6a66;
  text-decoration: underline;
}

.gladlyHC-answersIndex-item {
  padding-top: 12px;
}

.gladlyHC-answersIndex-item a {
  color: #000;
  text-decoration: none;
  font-weight: 400;
}

.gladlyHC-answersIndex-item a:hover {
  color: #fb6a66;
  text-decoration: underline;
}

a,
.gladlyHC-answerDetail-content ul a {
  color: #fb6a66;
  text-decoration: none;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  cursor: pointer;
}

a:hover,
.gladlyHC-answerDetail-content ul a:hover {
  color: #fb6a66;
  text-decoration: underline;
}

#gladly-help-center>div>div.gladlyHC-faqContainer>div>div.gladlyHC-faqSection.gladlyHC-faqSection-0>ul>li.gladlyHC-faqSection-listItem.gladlyHC-faqSection-listItem-more>a,
#gladly-help-center>div>div.gladlyHC-faqContainer>div>div.gladlyHC-faqSection.gladlyHC-faqSection-1>ul>li.gladlyHC-faqSection-listItem.gladlyHC-faqSection-listItem-more>a,
#gladly-help-center>div>div.gladlyHC-faqContainer>div>div.gladlyHC-faqSection.gladlyHC-faqSection-2>ul>li.gladlyHC-faqSection-listItem.gladlyHC-faqSection-listItem-more>a,
#gladly-help-center>div>div.gladlyHC-faqContainer>div>div.gladlyHC-faqSection.gladlyHC-faqSection-3>ul>li.gladlyHC-faqSection-listItem.gladlyHC-faqSection-listItem-more>a {
  color: #fb6a66;
  margin: 0px auto;
}

.highlightedSearchTerm,
.highlightedSearchTerm:hover {
  color: inherit;
}

.gladlyHC-searchMenu {
  max-width: 800px;
  padding: 24px 24px 18px;
  max-height: 60vh;
  overflow-y: scroll;
  box-shadow: 0 6px 10px rgb(0 0 0 / 15%);
  border: 1px solid #f0f0f0;
  border-radius: 6px;
}

.gladlyHC-searchMenu a {
  font-weight: 400;
  color: #000;
}

.gladlyHC-searchResult:hover {
  color: #fb6a66;
}

/* desktop only styles */
@media only screen and (min-width: 1024px) {
  .gladlyHC-searchContainer {
    background-image: url('../img/faq-bg.jpeg');
    padding: 450px 10px 100px;
    background-size: cover;
  }

  .gladlyHC-faqSection {
    width: 23%;
    flex-basis: initial !important;
  }

  #gladly-help-center>div>.gladlyHC-faqSection {
    width: 100%;
  }


  .gladlyHC-faqSection-listItem,
  .gladlyHC-answersIndex-item,
  .gladlyHC-answerDetail-content {
    text-align: left;
  }

  .gladlyHC-answerDetail img {
    max-width: 90%;
  }

  .gladlyHC-answersIndex-link,
  .gladlyHC-answersIndex-container,
  .gladlyHC-answerDetail,
  .gladlyHC-answerSection,
  .gladlyHC-searchResultsContainer {
    max-width: 50vw;
    margin: 0px auto 60px;
  }
}
